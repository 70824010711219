import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import {
  breakpointSizes,
  standardColours,
  brandFonts,
  fontWeights,
  fluidFontSize,
} from '../utilities';
import Animation from './Animation';
import Container from './Container';
import Divider from './Divider';

const StyledSelectNavigation = styled.section`
  text-align: center;
`;

const StyledSelectNavigationHeading = styled.h2`
  ${fluidFontSize('22px', '38px', breakpointSizes.tiny, breakpointSizes.large)}
  line-height: 1.3;
`;

const StyledSelectNavigationText = styled.p`
  margin-top: 20px;
  ${fluidFontSize('17px', '25px', breakpointSizes.tiny, breakpointSizes.large)}
  line-height: 1.5;
`;

const StyledSelectNavigationSelectWrap = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 20px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${standardColours.black};
    opacity: 0.02;
    pointer-events: none;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 24px;
    margin-top: -4px;
    height: 16px;
    width: 16px;
    border: 2px solid ${standardColours.darkerGrey};
    border-top: 0;
    border-left: 0;
    pointer-events: none;
    transform: translateY(-50%) rotate(45deg);
  }
`;

const StyledSelectNavigationSelect = styled.select`
  padding: 12px 62px 12px 20px;
  color: ${standardColours.darkerGrey};
  font-family: ${brandFonts.primary};
  font-weight: ${fontWeights.light};
  ${fluidFontSize('18px', '26px', breakpointSizes.tiny, breakpointSizes.large)}
  background: none;
  border: none;
  appearance: none;
`;

const SelectNavigation = ({ heading, text, items }) => {
  return (
    <StyledSelectNavigation>
      <Animation>
        <Container narrow={true}>
          <Divider />
          <StyledSelectNavigationHeading>
            {heading}
          </StyledSelectNavigationHeading>
          {text && (
            <StyledSelectNavigationText>{text}</StyledSelectNavigationText>
          )}
          {items && (
            <StyledSelectNavigationSelectWrap>
              <StyledSelectNavigationSelect
                defaultValue="select"
                onChange={event => navigate(event.target.value)}
              >
                <option value="select" selected="true" disabled>
                  Please select
                </option>
                {items.map(({ id, slug, title }) => {
                  return (
                    <option key={id} value={`/solutions/${slug}/`}>
                      {title}
                    </option>
                  );
                })}
              </StyledSelectNavigationSelect>
            </StyledSelectNavigationSelectWrap>
          )}
          <Divider alt="true" />
        </Container>
      </Animation>
    </StyledSelectNavigation>
  );
};

export default SelectNavigation;
