import React from 'react';
import styled from 'styled-components';
import { sectionMargins } from '../utilities';
import Animation from './Animation';
import DotsDivider from '../images/dots-divider.svg';
import MoleculesDivider from '../images/molecules-divider.svg';

const StyledDivider = styled.img`
  display: block;
  ${sectionMargins()};
  padding: 0 10px;
  width: 100%;
  max-height: 80px;
`;

const Divider = ({ alt }) => {
  return (
    <Animation>
      <StyledDivider
        src={alt ? MoleculesDivider : DotsDivider}
        loading="lazy"
        alt="Divider"
      />
    </Animation>
  );
};

export default Divider;
