import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import SelectNavigation from '../components/SelectNavigation';

const SolutionsPage = ({
  data: {
    datoCmsBannerImage: { solutionsBannerImage },
    allDatoCmsSolution: { nodes },
  },
}) => (
  <Layout>
    <HelmetDatoCms title="Business Solutions | Barcanet" />
    <main>
      <Banner heading="Solutions" image={solutionsBannerImage} />
      <SelectNavigation
        heading="Our Business Solutions"
        text="You have specific business challenges you need help with. What kind of solution are you looking for?"
        items={nodes}
      />
    </main>
  </Layout>
);

export const SolutionsPageQuery = graphql`
  query SolutionsPageQuery {
    datoCmsBannerImage {
      solutionsBannerImage {
        ...BannerImageFragment
      }
    }
    allDatoCmsSolution(sort: { fields: position }) {
      nodes {
        id
        slug
        title
      }
    }
  }
`;

export default SolutionsPage;
